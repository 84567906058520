import clsx from 'clsx';
import React from 'react';

import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import Button from '../../atoms/Button/Button';
import { TextExpoWebinarGroup } from '../../atoms/TextExpoWebinarGroup';
import { TMonthGroupProps } from '../../atoms/TextExpoWebinarGroup/TextExpoWebinarGroup';
import { Container } from '../../sections/Container';

type TTextsExpoWebinarsRow = {
  groupedExpos: TMonthGroupProps[];
  isLoading?: boolean;
  bottomContent?: string;
  buttons?: React.ComponentProps<typeof Button>[];
};

export const TextsExpoWebinarsRow: React.FC<TTextsExpoWebinarsRow> = ({
  groupedExpos,
  isLoading,
  buttons,
  bottomContent,
}) => {
  return (
    <section>
      <Container>
        <div className='flex flex-col'>
          {isLoading ? (
            <div className='size-full bg-surface-100'>
              <LoaderImg className='h-[708px] w-full md:h-[522px]' />
            </div>
          ) : (
            <>
              {!!groupedExpos.length &&
                groupedExpos.map((group, index) => (
                  <TextExpoWebinarGroup
                    key={index}
                    month={group.month}
                    newsItems={group.newsItems}
                  />
                ))}
              {buttons && buttons.length > 0 && (
                <div className='flex flex-row flex-wrap items-center gap-x-1 gap-y-[3px] pb-6 prose-a:leading-extra-loose lg:ps-[104px] xl:ps-[208px] xl:pt-6'>
                  {buttons.map((button, index) => (
                    <Button
                      className={clsx(
                        'flex prose-img:size-6',
                        index === 0 ? 'order-1' : 'order-3',
                      )}
                      key={index}
                      {...button}
                    >
                      {button.children}
                    </Button>
                  ))}
                  {bottomContent && (
                    <span className='order-2 flex text-lg font-medium leading-extra-loose text-interface-500'>
                      {bottomContent}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
};
